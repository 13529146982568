import React, { Component } from "react";

export default class Gospel extends Component {
  render() {
    return (
      <div>
        <p>Jesus Loves You!</p>
        <p>Jesus Loves You!</p>
        <p>Jesus Loves You!</p>
        <p>Jesus Loves You!</p>
        <p>Jesus Loves You!</p>
        <p>Jesus Loves You!</p>
        <p>Jesus Loves You!</p>
        <p>Jesus Loves You!</p>
      </div>
    );
  }
}
